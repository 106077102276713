export default [
    {
        "id": 166,
        "destinationTerminalCity": "Aba North",
        "destinationTerminalState": "Abia"
    },
    {
        "id": 167,
        "destinationTerminalCity": "Aba South",
        "destinationTerminalState": "Abia"
    },
    {
        "id": 169,
        "destinationTerminalCity": "Arochukwu",
        "destinationTerminalState": "Abia"
    },
    {
        "id": 170,
        "destinationTerminalCity": "Bende",
        "destinationTerminalState": "Abia"
    },
    {
        "id": 171,
        "destinationTerminalCity": "Ikwuano",
        "destinationTerminalState": "Abia"
    },
    {
        "id": 172,
        "destinationTerminalCity": "Isiala Ngwa",
        "destinationTerminalState": "Abia"
    },
    {
        "id": 173,
        "destinationTerminalCity": "Isuikwuato",
        "destinationTerminalState": "Abia"
    },
    {
        "id": 174,
        "destinationTerminalCity": "Obi Ngwa",
        "destinationTerminalState": "Abia"
    },
    {
        "id": 175,
        "destinationTerminalCity": "Ohafia",
        "destinationTerminalState": "Abia"
    },
    {
        "id": 176,
        "destinationTerminalCity": "Osisioma Ngwa",
        "destinationTerminalState": "Abia"
    },
    {
        "id": 177,
        "destinationTerminalCity": "Ugwunagbo",
        "destinationTerminalState": "Abia"
    },
    {
        "id": 178,
        "destinationTerminalCity": "Ukwa",
        "destinationTerminalState": "Abia"
    },
    {
        "id": 179,
        "destinationTerminalCity": "Umu Nneochi",
        "destinationTerminalState": "Abia"
    },
    {
        "id": 168,
        "destinationTerminalCity": "Umuahia",
        "destinationTerminalState": "Abia"
    },
    {
        "id": 6,
        "destinationTerminalCity": "Abaji",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 7,
        "destinationTerminalCity": "Apo District",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 8,
        "destinationTerminalCity": "Asokoro",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 9,
        "destinationTerminalCity": "Bwari",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 1,
        "destinationTerminalCity": "Central Business District",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 10,
        "destinationTerminalCity": "Dakibiyu",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 11,
        "destinationTerminalCity": "Dakwo District",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 12,
        "destinationTerminalCity": "Dei-Dei",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 13,
        "destinationTerminalCity": "Duboyi",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 14,
        "destinationTerminalCity": "Durumi",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 15,
        "destinationTerminalCity": "Dutse-Alhaji",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 16,
        "destinationTerminalCity": "Gaduwa",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 17,
        "destinationTerminalCity": "Galadimawa",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 18,
        "destinationTerminalCity": "Garki 1",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 19,
        "destinationTerminalCity": "Garki 2",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 20,
        "destinationTerminalCity": "Gudu",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 21,
        "destinationTerminalCity": "Guzape District",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 22,
        "destinationTerminalCity": "Gwagwa",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 23,
        "destinationTerminalCity": "Gwagwalada",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 2,
        "destinationTerminalCity": "Gwarinpa",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 24,
        "destinationTerminalCity": "Idu Industrial",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 25,
        "destinationTerminalCity": "Jabi",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 26,
        "destinationTerminalCity": "Jahi",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 27,
        "destinationTerminalCity": "Jikwoyi",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 28,
        "destinationTerminalCity": "Jiwa",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 29,
        "destinationTerminalCity": "Kabusa",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 30,
        "destinationTerminalCity": "Kado",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 31,
        "destinationTerminalCity": "Karmo",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 32,
        "destinationTerminalCity": "Karshi",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 33,
        "destinationTerminalCity": "Karu",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 34,
        "destinationTerminalCity": "Katampe",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 35,
        "destinationTerminalCity": "Kaura",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 36,
        "destinationTerminalCity": "Kpeyegyi",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 3,
        "destinationTerminalCity": "Kubwa",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 37,
        "destinationTerminalCity": "Kuchigoro",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 38,
        "destinationTerminalCity": "Kuje",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 39,
        "destinationTerminalCity": "Kurudu",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 40,
        "destinationTerminalCity": "Kwali",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 41,
        "destinationTerminalCity": "Lokogoma",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 42,
        "destinationTerminalCity": "Lugbe District",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 43,
        "destinationTerminalCity": "Mabushi",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 44,
        "destinationTerminalCity": "Maitama",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 45,
        "destinationTerminalCity": "Mararaba",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 46,
        "destinationTerminalCity": "Masaka",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 47,
        "destinationTerminalCity": "Mbora",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 48,
        "destinationTerminalCity": "Mpape",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 49,
        "destinationTerminalCity": "Nyanya",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 50,
        "destinationTerminalCity": "Okanje",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 51,
        "destinationTerminalCity": "Orozo",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 52,
        "destinationTerminalCity": "Pyakasa",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 53,
        "destinationTerminalCity": "Sabo Gida",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 54,
        "destinationTerminalCity": "Utako",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 55,
        "destinationTerminalCity": "Wumba",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 4,
        "destinationTerminalCity": "Wuse",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 5,
        "destinationTerminalCity": "Wuse 2",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 56,
        "destinationTerminalCity": "Wuye",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 57,
        "destinationTerminalCity": "Zuba",
        "destinationTerminalState" : "Abuja"
    },
    {
        "id": 182,
        "destinationTerminalCity": "Demsa",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 183,
        "destinationTerminalCity": "Fufore",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 184,
        "destinationTerminalCity": "Ganye",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 185,
        "destinationTerminalCity": "Girei",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 186,
        "destinationTerminalCity": "Gombi",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 187,
        "destinationTerminalCity": "Guyuk",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 188,
        "destinationTerminalCity": "Hong",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 189,
        "destinationTerminalCity": "Jada",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 190,
        "destinationTerminalCity": "Lamurde",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 191,
        "destinationTerminalCity": "Madagali",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 192,
        "destinationTerminalCity": "Maiha",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 193,
        "destinationTerminalCity": "Mayo-Belwa",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 194,
        "destinationTerminalCity": "Michika",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 195,
        "destinationTerminalCity": "Mubi North",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 196,
        "destinationTerminalCity": "Mubi South",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 197,
        "destinationTerminalCity": "Numan",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 198,
        "destinationTerminalCity": "Shelleng",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 199,
        "destinationTerminalCity": "Song",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 200,
        "destinationTerminalCity": "Toungo",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 180,
        "destinationTerminalCity": "Yola North",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 181,
        "destinationTerminalCity": "Yola South",
        "destinationTerminalState" : "Adamawa"
    },
    {
        "id": 201,
        "destinationTerminalCity": "Abak",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 205,
        "destinationTerminalCity": "Eastern Obolo",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 206,
        "destinationTerminalCity": "Esit-Eket",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 207,
        "destinationTerminalCity": "Essien Udim",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 208,
        "destinationTerminalCity": "Etim-Ekpo",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 209,
        "destinationTerminalCity": "Etinan",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 210,
        "destinationTerminalCity": "Ibeno",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 211,
        "destinationTerminalCity": "Ibesikpo Asutan",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 212,
        "destinationTerminalCity": "Ibiono Ibom",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 213,
        "destinationTerminalCity": "Ika",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 214,
        "destinationTerminalCity": "Ikono",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 215,
        "destinationTerminalCity": "Ikot Abasi",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 202,
        "destinationTerminalCity": "Ikot Ekpene",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 216,
        "destinationTerminalCity": "Ini",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 217,
        "destinationTerminalCity": "Itu",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 218,
        "destinationTerminalCity": "Mbo",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 219,
        "destinationTerminalCity": "Mkpat Enin",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 220,
        "destinationTerminalCity": "Nsit Atai",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 221,
        "destinationTerminalCity": "Nsit Ibom",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 222,
        "destinationTerminalCity": "Nsit Ubium",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 223,
        "destinationTerminalCity": "Obot Akara",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 224,
        "destinationTerminalCity": "Okobo",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 225,
        "destinationTerminalCity": "Onna",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 203,
        "destinationTerminalCity": "Oron",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 226,
        "destinationTerminalCity": "Oruk Anam",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 227,
        "destinationTerminalCity": "Udung Uko",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 228,
        "destinationTerminalCity": "Ukanafun",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 229,
        "destinationTerminalCity": "Uquo-Ibeno",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 230,
        "destinationTerminalCity": "Uruan",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 231,
        "destinationTerminalCity": "Urue-Offong/Oruko",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 204,
        "destinationTerminalCity": "Uyo",
        "destinationTerminalState": "Akwa Ibom"
    },
    {
        "id": 236,
        "destinationTerminalCity": "Aghamelu",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 237,
        "destinationTerminalCity": "Aguata",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 238,
        "destinationTerminalCity": "Anambra East",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 239,
        "destinationTerminalCity": "Anambra West",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 240,
        "destinationTerminalCity": "Anaocha",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 232,
        "destinationTerminalCity": "Awka",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 241,
        "destinationTerminalCity": "Ayamelum",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 242,
        "destinationTerminalCity": "Dunukofia",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 243,
        "destinationTerminalCity": "Ekwusigo",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 233,
        "destinationTerminalCity": "Idemili",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 244,
        "destinationTerminalCity": "Ihiala",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 245,
        "destinationTerminalCity": "Njikoka",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 234,
        "destinationTerminalCity": "Nnewi",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 246,
        "destinationTerminalCity": "Ogbaru",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 235,
        "destinationTerminalCity": "Onitsha",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 247,
        "destinationTerminalCity": "Orumba",
        "destinationTerminalState": "Anambra"
    },
    {
        "id": 250,
        "destinationTerminalCity": "Alkaleri",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 248,
        "destinationTerminalCity": "Bauchi",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 249,
        "destinationTerminalCity": "Bogoro",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 251,
        "destinationTerminalCity": "Damban",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 252,
        "destinationTerminalCity": "Darazo",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 253,
        "destinationTerminalCity": "Dass",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 254,
        "destinationTerminalCity": "Gamawa",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 255,
        "destinationTerminalCity": "Ganjuwa",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 256,
        "destinationTerminalCity": "Giade",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 257,
        "destinationTerminalCity": "Itas/Gadau",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 258,
        "destinationTerminalCity": "Jama are",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 259,
        "destinationTerminalCity": "Katagum",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 260,
        "destinationTerminalCity": "Kirfi",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 261,
        "destinationTerminalCity": "Misau",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 262,
        "destinationTerminalCity": "Ningi",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 263,
        "destinationTerminalCity": "Shira",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 264,
        "destinationTerminalCity": "Toro",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 265,
        "destinationTerminalCity": "Warji",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 266,
        "destinationTerminalCity": "Zaki",
        "destinationTerminalState": "Bauchi"
    },
    {
        "id": 268,
        "destinationTerminalCity": "Brass",
        "destinationTerminalState": "Bayelsa"
    },
    {
        "id": 269,
        "destinationTerminalCity": "Ekeremor",
        "destinationTerminalState": "Bayelsa"
    },
    {
        "id": 270,
        "destinationTerminalCity": "Kolokuma/Opokuma",
        "destinationTerminalState": "Bayelsa"
    },
    {
        "id": 271,
        "destinationTerminalCity": "Nembe",
        "destinationTerminalState": "Bayelsa"
    },
    {
        "id": 272,
        "destinationTerminalCity": "Ogbia",
        "destinationTerminalState": "Bayelsa"
    },
    {
        "id": 273,
        "destinationTerminalCity": "Sagbama",
        "destinationTerminalState": "Bayelsa"
    },
    {
        "id": 274,
        "destinationTerminalCity": "Southern Ijaw",
        "destinationTerminalState": "Bayelsa"
    },
    {
        "id": 267,
        "destinationTerminalCity": "Yenagoa",
        "destinationTerminalState": "Bayelsa"
    },
    {
        "id": 279,
        "destinationTerminalCity": "Ado",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 280,
        "destinationTerminalCity": "Agatu",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 281,
        "destinationTerminalCity": "Apa",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 282,
        "destinationTerminalCity": "Buruku",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 275,
        "destinationTerminalCity": "Gboko",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 283,
        "destinationTerminalCity": "Guma",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 284,
        "destinationTerminalCity": "Gwer",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 276,
        "destinationTerminalCity": "Katsina-Ala",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 285,
        "destinationTerminalCity": "Konshisha",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 286,
        "destinationTerminalCity": "Kwande",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 287,
        "destinationTerminalCity": "Logo",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 277,
        "destinationTerminalCity": "Makurdi",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 288,
        "destinationTerminalCity": "Obi",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 289,
        "destinationTerminalCity": "Ogbadibo",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 290,
        "destinationTerminalCity": "Ohimini",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 291,
        "destinationTerminalCity": "Oju",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 292,
        "destinationTerminalCity": "Okpokwu",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 278,
        "destinationTerminalCity": "Otukpo",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 293,
        "destinationTerminalCity": "Tarka",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 294,
        "destinationTerminalCity": "Ukum",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 295,
        "destinationTerminalCity": "Ushongo",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 296,
        "destinationTerminalCity": "Vandeikya",
        "destinationTerminalState": "Benue"
    },
    {
        "id": 298,
        "destinationTerminalCity": "Abadam",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 299,
        "destinationTerminalCity": "Askira/Uba",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 300,
        "destinationTerminalCity": "Bama",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 301,
        "destinationTerminalCity": "Bayo",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 302,
        "destinationTerminalCity": "Biu",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 303,
        "destinationTerminalCity": "Chibok",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 304,
        "destinationTerminalCity": "Damboa",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 305,
        "destinationTerminalCity": "Dikwa",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 306,
        "destinationTerminalCity": "Gubio",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 307,
        "destinationTerminalCity": "Guzamala",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 308,
        "destinationTerminalCity": "Gwoza",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 309,
        "destinationTerminalCity": "Hawul",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 310,
        "destinationTerminalCity": "Jere",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 311,
        "destinationTerminalCity": "Kaga",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 312,
        "destinationTerminalCity": "Kala/Balge",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 313,
        "destinationTerminalCity": "Konduga",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 314,
        "destinationTerminalCity": "Kukawa",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 315,
        "destinationTerminalCity": "Kwaya Kusar",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 316,
        "destinationTerminalCity": "Mafa",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 317,
        "destinationTerminalCity": "Magumeri",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 297,
        "destinationTerminalCity": "Maiduguri",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 318,
        "destinationTerminalCity": "Marte",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 319,
        "destinationTerminalCity": "Mobbar",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 320,
        "destinationTerminalCity": "Monguno",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 321,
        "destinationTerminalCity": "Ngala",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 322,
        "destinationTerminalCity": "Nganzai",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 323,
        "destinationTerminalCity": "Shani",
        "destinationTerminalState": "Borno"
    },
    {
        "id": 327,
        "destinationTerminalCity": "Abi",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 328,
        "destinationTerminalCity": "Akamkpa",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 329,
        "destinationTerminalCity": "Akpabuyo",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 330,
        "destinationTerminalCity": "Bakassi",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 331,
        "destinationTerminalCity": "Bekwara",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 332,
        "destinationTerminalCity": "Biase",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 333,
        "destinationTerminalCity": "Boki",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 324,
        "destinationTerminalCity": "Calabar",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 334,
        "destinationTerminalCity": "Etung",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 325,
        "destinationTerminalCity": "Ikom",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 335,
        "destinationTerminalCity": "Obanliku",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 336,
        "destinationTerminalCity": "Obubra",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 337,
        "destinationTerminalCity": "Obudu",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 338,
        "destinationTerminalCity": "Odukpani",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 326,
        "destinationTerminalCity": "Ogoja",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 339,
        "destinationTerminalCity": "Yakuur",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 340,
        "destinationTerminalCity": "Yala",
        "destinationTerminalState": "Cross River"
    },
    {
        "id": 346,
        "destinationTerminalCity": "Aniocha North",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 346,
        "destinationTerminalCity": "Asaba",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 347,
        "destinationTerminalCity": "Aniocha South",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 348,
        "destinationTerminalCity": "Bomadi",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 349,
        "destinationTerminalCity": "Burutu",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 350,
        "destinationTerminalCity": "Ethiope East",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 351,
        "destinationTerminalCity": "Ethiope West",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 352,
        "destinationTerminalCity": "Ika North East",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 353,
        "destinationTerminalCity": "Ika South",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 354,
        "destinationTerminalCity": "Isoko",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 355,
        "destinationTerminalCity": "Ndokwa East",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 356,
        "destinationTerminalCity": "Ndokwa West",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 357,
        "destinationTerminalCity": "Okpe",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 358,
        "destinationTerminalCity": "Oshimili North",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 341,
        "destinationTerminalCity": "Oshimili South",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 359,
        "destinationTerminalCity": "Patani",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 342,
        "destinationTerminalCity": "Sapele",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 360,
        "destinationTerminalCity": "Udu",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 343,
        "destinationTerminalCity": "Ughelli",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 344,
        "destinationTerminalCity": "Uvwie",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 345,
        "destinationTerminalCity": "Warri",
        "destinationTerminalState": "Delta"
    },
    {
        "id": 361,
        "destinationTerminalCity": "Abakaliki",
        "destinationTerminalState": "Ebonyi"
    },
    {
        "id": 362,
        "destinationTerminalCity": "Afikpo North",
        "destinationTerminalState": "Ebonyi"
    },
    {
        "id": 363,
        "destinationTerminalCity": "Afikpo South",
        "destinationTerminalState": "Ebonyi"
    },
    {
        "id": 364,
        "destinationTerminalCity": "Ebonyi",
        "destinationTerminalState": "Ebonyi"
    },
    {
        "id": 365,
        "destinationTerminalCity": "Ezza",
        "destinationTerminalState": "Ebonyi"
    },
    {
        "id": 366,
        "destinationTerminalCity": "Ikwo",
        "destinationTerminalState": "Ebonyi"
    },
    {
        "id": 367,
        "destinationTerminalCity": "Ishielu",
        "destinationTerminalState": "Ebonyi"
    },
    {
        "id": 368,
        "destinationTerminalCity": "Ivo",
        "destinationTerminalState": "Ebonyi"
    },
    {
        "id": 369,
        "destinationTerminalCity": "Izzi",
        "destinationTerminalState": "Ebonyi"
    },
    {
        "id": 370,
        "destinationTerminalCity": "Ohaozara",
        "destinationTerminalState": "Ebonyi"
    },
    {
        "id": 371,
        "destinationTerminalCity": "Ohaukwu",
        "destinationTerminalState": "Ebonyi"
    },
    {
        "id": 372,
        "destinationTerminalCity": "Onicha",
        "destinationTerminalState": "Ebonyi"
    },
    {
        "id": 378,
        "destinationTerminalCity": "Akoko-Edo",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 379,
        "destinationTerminalCity": "Auchi",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 373,
        "destinationTerminalCity": "Benin City",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 374,
        "destinationTerminalCity": "Egor",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 375,
        "destinationTerminalCity": "Ekpoma",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 380,
        "destinationTerminalCity": "Esan North East",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 381,
        "destinationTerminalCity": "Fugar",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 382,
        "destinationTerminalCity": "Igueben",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 376,
        "destinationTerminalCity": "Ikpoba-Okha",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 383,
        "destinationTerminalCity": "Irrua",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 377,
        "destinationTerminalCity": "Okada",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 384,
        "destinationTerminalCity": "Orhionmwon",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 385,
        "destinationTerminalCity": "Ovia South",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 386,
        "destinationTerminalCity": "Owan",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 387,
        "destinationTerminalCity": "Ubiaja",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 388,
        "destinationTerminalCity": "Uhunmwonde",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 389,
        "destinationTerminalCity": "Uromi",
        "destinationTerminalState": "Edo"
    },
    {
        "id": 389,
        "destinationTerminalCity": "Ado Ekiti",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 394,
        "destinationTerminalCity": "Aiyekire (Gbonyin)",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 395,
        "destinationTerminalCity": "Aramoko",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 396,
        "destinationTerminalCity": "Efon",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 397,
        "destinationTerminalCity": "Emure",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 390,
        "destinationTerminalCity": "Ido-Osi",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 398,
        "destinationTerminalCity": "Ijero",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 391,
        "destinationTerminalCity": "Ikere",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 392,
        "destinationTerminalCity": "Ikole",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 393,
        "destinationTerminalCity": "Ilawe",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 399,
        "destinationTerminalCity": "Ilejemeje",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 400,
        "destinationTerminalCity": "Irepodun/Ifelodun",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 401,
        "destinationTerminalCity": "Ise/Orun",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 402,
        "destinationTerminalCity": "Moba",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 403,
        "destinationTerminalCity": "Omuo",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 404,
        "destinationTerminalCity": "Oye",
        "destinationTerminalState": "Ekiti"
    },
    {
        "id": 409,
        "destinationTerminalCity": "Aninri",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 410,
        "destinationTerminalCity": "Awgu",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 405,
        "destinationTerminalCity": "Enugu",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 411,
        "destinationTerminalCity": "Ezeagu",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 412,
        "destinationTerminalCity": "Igbo Eze South",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 413,
        "destinationTerminalCity": "Igbo-Etiti",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 414,
        "destinationTerminalCity": "Igbo-Eze North",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 415,
        "destinationTerminalCity": "Isi-Uzo",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 416,
        "destinationTerminalCity": "Nkanu East",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 406,
        "destinationTerminalCity": "Nkanu West",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 407,
        "destinationTerminalCity": "Nsukka",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 417,
        "destinationTerminalCity": "Oji-River",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 418,
        "destinationTerminalCity": "Udenu",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 408,
        "destinationTerminalCity": "Udi",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 419,
        "destinationTerminalCity": "Uzo-Uwani",
        "destinationTerminalState": "Enugu"
    },
    {
        "id": 421,
        "destinationTerminalCity": "Akko",
        "destinationTerminalState": "Gombe"
    },
    {
        "id": 422,
        "destinationTerminalCity": "Balanga",
        "destinationTerminalState": "Gombe"
    },
    {
        "id": 423,
        "destinationTerminalCity": "Billiri",
        "destinationTerminalState": "Gombe"
    },
    {
        "id": 424,
        "destinationTerminalCity": "Dukku",
        "destinationTerminalState": "Gombe"
    },
    {
        "id": 425,
        "destinationTerminalCity": "Funakaye",
        "destinationTerminalState": "Gombe"
    },
    {
        "id": 420,
        "destinationTerminalCity": "Gombe",
        "destinationTerminalState": "Gombe"
    },
    {
        "id": 426,
        "destinationTerminalCity": "Kaltungo",
        "destinationTerminalState": "Gombe"
    },
    {
        "id": 427,
        "destinationTerminalCity": "Kwami",
        "destinationTerminalState": "Gombe"
    },
    {
        "id": 428,
        "destinationTerminalCity": "Nafada",
        "destinationTerminalState": "Gombe"
    },
    {
        "id": 429,
        "destinationTerminalCity": "Shomgom",
        "destinationTerminalState": "Gombe"
    },
    {
        "id": 430,
        "destinationTerminalCity": "Yamaltu/Deba",
        "destinationTerminalState": "Gombe"
    },
    {
        "id": 436,
        "destinationTerminalCity": "Aboh-Mbaise",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 437,
        "destinationTerminalCity": "Ahiazu-Mbaise",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 438,
        "destinationTerminalCity": "Ehime-Mbano",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 439,
        "destinationTerminalCity": "Ezinihitte",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 440,
        "destinationTerminalCity": "Ezinihitte Mbaise",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 441,
        "destinationTerminalCity": "Ideato North",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 442,
        "destinationTerminalCity": "Ideato South",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 443,
        "destinationTerminalCity": "Ihitte/Uboma",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 431,
        "destinationTerminalCity": "Ikeduru",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 444,
        "destinationTerminalCity": "Isiala Mbano",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 445,
        "destinationTerminalCity": "Isu",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 432,
        "destinationTerminalCity": "Mbaitoli",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 446,
        "destinationTerminalCity": "Ngor-Okpala",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 447,
        "destinationTerminalCity": "Njaba",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 448,
        "destinationTerminalCity": "Nkwerre",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 449,
        "destinationTerminalCity": "Nwangele",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 450,
        "destinationTerminalCity": "Obowo",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 451,
        "destinationTerminalCity": "Oguta",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 452,
        "destinationTerminalCity": "Ohaji/Egbema",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 433,
        "destinationTerminalCity": "Okigwe",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 453,
        "destinationTerminalCity": "Onuimo",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 434,
        "destinationTerminalCity": "Orlu",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 454,
        "destinationTerminalCity": "Orsu",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 455,
        "destinationTerminalCity": "Oru",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 435,
        "destinationTerminalCity": "Owerri",
        "destinationTerminalState": "Imo"
    },
    {
        "id": 458,
        "destinationTerminalCity": "Auyo",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 459,
        "destinationTerminalCity": "Babura",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 460,
        "destinationTerminalCity": "Biriniwa",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 461,
        "destinationTerminalCity": "Buji",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 456,
        "destinationTerminalCity": "Dutse-Jigawa",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 462,
        "destinationTerminalCity": "Gagarawa",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 457,
        "destinationTerminalCity": "Garki",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 463,
        "destinationTerminalCity": "Gumel",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 464,
        "destinationTerminalCity": "Guri",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 465,
        "destinationTerminalCity": "Gwaram",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 466,
        "destinationTerminalCity": "Gwiwa",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 467,
        "destinationTerminalCity": "Hadejia",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 468,
        "destinationTerminalCity": "Jahun",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 469,
        "destinationTerminalCity": "Kafin Hausa",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 470,
        "destinationTerminalCity": "Kaugama",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 471,
        "destinationTerminalCity": "Kazaure",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 472,
        "destinationTerminalCity": "Kiri Kasamma",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 473,
        "destinationTerminalCity": "Kiyawa",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 474,
        "destinationTerminalCity": "Maigatari",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 475,
        "destinationTerminalCity": "Malam Madori",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 476,
        "destinationTerminalCity": "Miga",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 477,
        "destinationTerminalCity": "Ringim",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 478,
        "destinationTerminalCity": "Roni",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 479,
        "destinationTerminalCity": "Sule-Tankarkar",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 480,
        "destinationTerminalCity": "Taura",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 481,
        "destinationTerminalCity": "Yankwashi",
        "destinationTerminalState": "Jigawa"
    },
    {
        "id": 486,
        "destinationTerminalCity": "Birnin-Gwari",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 482,
        "destinationTerminalCity": "Chikun",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 487,
        "destinationTerminalCity": "Giwa",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 483,
        "destinationTerminalCity": "Igabi",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 488,
        "destinationTerminalCity": "Ikara",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 489,
        "destinationTerminalCity": "Jaba",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 490,
        "destinationTerminalCity": "Jema a",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 491,
        "destinationTerminalCity": "Kachia",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 484,
        "destinationTerminalCity": "Kaduna",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 492,
        "destinationTerminalCity": "Kagarko",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 493,
        "destinationTerminalCity": "Kajuru",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 494,
        "destinationTerminalCity": "Kaura-Kaduna",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 495,
        "destinationTerminalCity": "Kauru",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 496,
        "destinationTerminalCity": "Kubau",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 497,
        "destinationTerminalCity": "Kudan",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 498,
        "destinationTerminalCity": "Lere",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 499,
        "destinationTerminalCity": "Makarfi",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 500,
        "destinationTerminalCity": "Sanga",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 501,
        "destinationTerminalCity": "Soba",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 502,
        "destinationTerminalCity": "Zango-Kataf",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 485,
        "destinationTerminalCity": "Zaria",
        "destinationTerminalState": "Kaduna"
    },
    {
        "id": 507,
        "destinationTerminalCity": "Ajingi",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 508,
        "destinationTerminalCity": "Albasu",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 509,
        "destinationTerminalCity": "Bagwai",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 510,
        "destinationTerminalCity": "Bebeji",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 511,
        "destinationTerminalCity": "Bichi",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 512,
        "destinationTerminalCity": "Bunkure",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 513,
        "destinationTerminalCity": "Dala",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 514,
        "destinationTerminalCity": "Dambatta",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 515,
        "destinationTerminalCity": "Dawakin Kudu",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 516,
        "destinationTerminalCity": "Dawakin Tofa",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 517,
        "destinationTerminalCity": "Doguwa",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 503,
        "destinationTerminalCity": "Fagge",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 518,
        "destinationTerminalCity": "Gabasawa",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 519,
        "destinationTerminalCity": "Garko",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 520,
        "destinationTerminalCity": "Garum Mallam",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 521,
        "destinationTerminalCity": "Garun Mallam",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 522,
        "destinationTerminalCity": "Gaya",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 523,
        "destinationTerminalCity": "Gezawa",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 524,
        "destinationTerminalCity": "Gwale",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 525,
        "destinationTerminalCity": "Gwarzo",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 526,
        "destinationTerminalCity": "Kabo",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 504,
        "destinationTerminalCity": "Kano Municipal",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 527,
        "destinationTerminalCity": "Karaye",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 528,
        "destinationTerminalCity": "Kibiya",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 529,
        "destinationTerminalCity": "Kiru",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 530,
        "destinationTerminalCity": "Kumbotso",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 531,
        "destinationTerminalCity": "Kunchi",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 532,
        "destinationTerminalCity": "Kura",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 533,
        "destinationTerminalCity": "Madobi",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 534,
        "destinationTerminalCity": "Makoda",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 535,
        "destinationTerminalCity": "Minjibir",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 505,
        "destinationTerminalCity": "Nasarawa-Kano",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 536,
        "destinationTerminalCity": "Rano",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 537,
        "destinationTerminalCity": "Rimin Gado",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 538,
        "destinationTerminalCity": "Rogo",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 539,
        "destinationTerminalCity": "Shanono",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 540,
        "destinationTerminalCity": "Sumaila",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 541,
        "destinationTerminalCity": "Takai",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 506,
        "destinationTerminalCity": "Tarauni",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 542,
        "destinationTerminalCity": "Tofa",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 543,
        "destinationTerminalCity": "Tsanyawa",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 544,
        "destinationTerminalCity": "Tudun Wada",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 545,
        "destinationTerminalCity": "Ungogo",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 546,
        "destinationTerminalCity": "Warawa",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 547,
        "destinationTerminalCity": "Wudil",
        "destinationTerminalState": "Kano"
    },
    {
        "id": 552,
        "destinationTerminalCity": "Bakori",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 553,
        "destinationTerminalCity": "Batagarawa",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 554,
        "destinationTerminalCity": "Batsari",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 555,
        "destinationTerminalCity": "Baure",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 556,
        "destinationTerminalCity": "Bindawa",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 557,
        "destinationTerminalCity": "Charanchi",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 558,
        "destinationTerminalCity": "Dan Musa",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 559,
        "destinationTerminalCity": "Dandume",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 548,
        "destinationTerminalCity": "Danja",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 549,
        "destinationTerminalCity": "Daura",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 560,
        "destinationTerminalCity": "Dutsi",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 561,
        "destinationTerminalCity": "Dutsin-Ma",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 562,
        "destinationTerminalCity": "Faskari",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 563,
        "destinationTerminalCity": "Funtua",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 564,
        "destinationTerminalCity": "Ingawa",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 565,
        "destinationTerminalCity": "Jibia",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 566,
        "destinationTerminalCity": "Kafur",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 567,
        "destinationTerminalCity": "Kaita",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 568,
        "destinationTerminalCity": "Kankara",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 569,
        "destinationTerminalCity": "Kankia",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 550,
        "destinationTerminalCity": "Katsina",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 570,
        "destinationTerminalCity": "Kurfi",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 571,
        "destinationTerminalCity": "Kusada",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 572,
        "destinationTerminalCity": "Mai adua",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 573,
        "destinationTerminalCity": "Malumfashi",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 574,
        "destinationTerminalCity": "Mani",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 575,
        "destinationTerminalCity": "Mashi",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 576,
        "destinationTerminalCity": "Matazu",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 577,
        "destinationTerminalCity": "Musawa",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 578,
        "destinationTerminalCity": "Rimi",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 579,
        "destinationTerminalCity": "Sabuwa",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 580,
        "destinationTerminalCity": "Safana",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 581,
        "destinationTerminalCity": "Sandamu",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 551,
        "destinationTerminalCity": "Zango",
        "destinationTerminalState": "Katsina"
    },
    {
        "id": 585,
        "destinationTerminalCity": "Aleiro",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 586,
        "destinationTerminalCity": "Arewa-Dandi",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 587,
        "destinationTerminalCity": "Argungu",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 588,
        "destinationTerminalCity": "Augie",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 589,
        "destinationTerminalCity": "Bagudo",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 582,
        "destinationTerminalCity": "Birnin Kebbi",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 590,
        "destinationTerminalCity": "Bunza",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 591,
        "destinationTerminalCity": "Dandi",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 592,
        "destinationTerminalCity": "Fakai",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 593,
        "destinationTerminalCity": "Gwandu",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 583,
        "destinationTerminalCity": "Jega",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 594,
        "destinationTerminalCity": "Kalgo",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 595,
        "destinationTerminalCity": "Koko/Besse",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 596,
        "destinationTerminalCity": "Maiyama",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 597,
        "destinationTerminalCity": "Ngaski",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 598,
        "destinationTerminalCity": "Sakaba",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 599,
        "destinationTerminalCity": "Shanga",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 600,
        "destinationTerminalCity": "Suru",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 601,
        "destinationTerminalCity": "Wasagu/Danko",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 602,
        "destinationTerminalCity": "Yauri",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 584,
        "destinationTerminalCity": "Zuru",
        "destinationTerminalState":"Kebbi"
    },
    {
        "id": 605,
        "destinationTerminalCity": "Adavi",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 606,
        "destinationTerminalCity": "Ajaokuta",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 607,
        "destinationTerminalCity": "Ankpa",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 608,
        "destinationTerminalCity": "Bassa",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 609,
        "destinationTerminalCity": "Dekina",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 610,
        "destinationTerminalCity": "Ibaji",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 611,
        "destinationTerminalCity": "Idah",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 612,
        "destinationTerminalCity": "Igala Mela",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 613,
        "destinationTerminalCity": "Igalamela-Odolu",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 614,
        "destinationTerminalCity": "Ijumu",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 615,
        "destinationTerminalCity": "Kabba/Bunu",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 616,
        "destinationTerminalCity": "Kogi",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 617,
        "destinationTerminalCity": "Koton Karfe",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 603,
        "destinationTerminalCity": "Lokoja",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 618,
        "destinationTerminalCity": "Mopa-Muro",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 619,
        "destinationTerminalCity": "Ofu",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 620,
        "destinationTerminalCity": "Ogori/Magongo",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 621,
        "destinationTerminalCity": "Okehi",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 604,
        "destinationTerminalCity": "Okene",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 622,
        "destinationTerminalCity": "Olamaboro",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 623,
        "destinationTerminalCity": "Omala",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 624,
        "destinationTerminalCity": "Yagba East",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 625,
        "destinationTerminalCity": "Yagba West",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 629,
        "destinationTerminalCity": "Asa",
        "destinationTerminalState":"Kwara"
    },
    {
        "id": 630,
        "destinationTerminalCity": "Baruten",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 631,
        "destinationTerminalCity": "Edu",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 632,
        "destinationTerminalCity": "Ekiti-Kwara",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 633,
        "destinationTerminalCity": "Ifelodun-Kwara",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 626,
        "destinationTerminalCity": "Ilorin East",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 627,
        "destinationTerminalCity": "Ilorin South",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 628,
        "destinationTerminalCity": "Ilorin West",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 634,
        "destinationTerminalCity": "Irepodun-Kwara",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 635,
        "destinationTerminalCity": "Isin",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 636,
        "destinationTerminalCity": "Kaiama",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 637,
        "destinationTerminalCity": "Moro",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 638,
        "destinationTerminalCity": "Oke-Ero",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 639,
        "destinationTerminalCity": "Oyun",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 640,
        "destinationTerminalCity": "Pategi",
        "destinationTerminalState":"Kogi"
    },
    {
        "id": 63,
        "destinationTerminalCity": "Abule Egba",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 64,
        "destinationTerminalCity": "Agbara-Igbesan",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 65,
        "destinationTerminalCity": "Agboyi/Ketu",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 66,
        "destinationTerminalCity": "Agege",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 58,
        "destinationTerminalCity": "Ajah",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 59,
        "destinationTerminalCity": "Alimosho",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 67,
        "destinationTerminalCity": "Amuwo-Odofin",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 68,
        "destinationTerminalCity": "Badagry",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 69,
        "destinationTerminalCity": "Egbe Idimu",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 70,
        "destinationTerminalCity": "Ejigbo",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 71,
        "destinationTerminalCity": "Eko Atlantic",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 72,
        "destinationTerminalCity": "Epe",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 73,
        "destinationTerminalCity": "Gbagada",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 74,
        "destinationTerminalCity": "Ibeju",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 75,
        "destinationTerminalCity": "Ifako-Ijaiye",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 60,
        "destinationTerminalCity": "Ikeja",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 76,
        "destinationTerminalCity": "Ikorodu",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 77,
        "destinationTerminalCity": "Ikotun/Igando",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 78,
        "destinationTerminalCity": "Ikoyi",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 79,
        "destinationTerminalCity": "Ilashe",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 80,
        "destinationTerminalCity": "Ilupeju",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 81,
        "destinationTerminalCity": "Ipaja",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 82,
        "destinationTerminalCity": "Isolo",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 83,
        "destinationTerminalCity": "Kosofe",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 84,
        "destinationTerminalCity": "Lagos Island (Eko)",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 85,
        "destinationTerminalCity": "Lekki",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 86,
        "destinationTerminalCity": "Magodo",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 87,
        "destinationTerminalCity": "Maryland",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 88,
        "destinationTerminalCity": "Mushin",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 89,
        "destinationTerminalCity": "Ogba",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 90,
        "destinationTerminalCity": "Ogudu",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 61,
        "destinationTerminalCity": "Ojo",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 91,
        "destinationTerminalCity": "Ojodu",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 92,
        "destinationTerminalCity": "Ojota",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 93,
        "destinationTerminalCity": "Orile",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 94,
        "destinationTerminalCity": "Oshodi",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 95,
        "destinationTerminalCity": "Shomolu",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 62,
        "destinationTerminalCity": "Surulere",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 96,
        "destinationTerminalCity": "Tarkwa Bay Island",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 97,
        "destinationTerminalCity": "Victoria Island",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 98,
        "destinationTerminalCity": "Yaba",
        "destinationTerminalState":"Lagos"
    },
    {
        "id": 644,
        "destinationTerminalCity": "Akwanga",
        "destinationTerminalState":"Nasarawa"
    },
    {
        "id": 645,
        "destinationTerminalCity": "Awe",
        "destinationTerminalState":"Nasarawa"
    },
    {
        "id": 646,
        "destinationTerminalCity": "Doma",
        "destinationTerminalState":"Nasarawa"
    },
    {
        "id": 641,
        "destinationTerminalCity": "Karu-Nasarawa",
        "destinationTerminalState":"Nasarawa"
    },
    {
        "id": 647,
        "destinationTerminalCity": "Keana",
        "destinationTerminalState":"Nasarawa"
    },
    {
        "id": 642,
        "destinationTerminalCity": "Keffi",
        "destinationTerminalState":"Nasarawa"
    },
    {
        "id": 648,
        "destinationTerminalCity": "Kokona",
        "destinationTerminalState":"Nasarawa"
    },
    {
        "id": 643,
        "destinationTerminalCity": "Lafia",
        "destinationTerminalState":"Nasarawa"
    },
    {
        "id": 649,
        "destinationTerminalCity": "Nasarawa",
        "destinationTerminalState":"Nasarawa"
    },
    {
        "id": 650,
        "destinationTerminalCity": "Nasarawa-Eggon",
        "destinationTerminalState":"Nasarawa"
    },
    {
        "id": 651,
        "destinationTerminalCity": "Obi-Nasarawa",
        "destinationTerminalState":"Nasarawa"
    },
    {
        "id": 652,
        "destinationTerminalCity": "Toto",
        "destinationTerminalState":"Nasarawa"
    },
    {
        "id": 658,
        "destinationTerminalCity": "Agaie",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 659,
        "destinationTerminalCity": "Agwara",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 653,
        "destinationTerminalCity": "Bida",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 660,
        "destinationTerminalCity": "Borgu",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 654,
        "destinationTerminalCity": "Bosso",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 655,
        "destinationTerminalCity": "Chanchaga",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 661,
        "destinationTerminalCity": "Edati",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 662,
        "destinationTerminalCity": "Gbako",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 663,
        "destinationTerminalCity": "Gurara",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 664,
        "destinationTerminalCity": "Kontagora",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 665,
        "destinationTerminalCity": "Lapai",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 666,
        "destinationTerminalCity": "Lavun",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 667,
        "destinationTerminalCity": "Magama",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 668,
        "destinationTerminalCity": "Mariga",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 669,
        "destinationTerminalCity": "Mashegu",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 656,
        "destinationTerminalCity": "Minna",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 670,
        "destinationTerminalCity": "Mokwa",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 671,
        "destinationTerminalCity": "Muya",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 672,
        "destinationTerminalCity": "Paikoro",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 673,
        "destinationTerminalCity": "Rafi",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 674,
        "destinationTerminalCity": "Rijau",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 675,
        "destinationTerminalCity": "Shiroro",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 657,
        "destinationTerminalCity": "Suleja",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 676,
        "destinationTerminalCity": "Tafa",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 677,
        "destinationTerminalCity": "Wushishi",
        "destinationTerminalState":"Niger"
    },
    {
        "id": 104,
        "destinationTerminalCity": "Abeokuta North",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 99,
        "destinationTerminalCity": "Abeokuta South",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 100,
        "destinationTerminalCity": "Ado-Odo/Ota",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 105,
        "destinationTerminalCity": "Ayetoro",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 106,
        "destinationTerminalCity": "Ewekoro",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 107,
        "destinationTerminalCity": "Ifo",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 108,
        "destinationTerminalCity": "Ijebu",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 101,
        "destinationTerminalCity": "Ijebu Ode",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 109,
        "destinationTerminalCity": "Ikenne",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 110,
        "destinationTerminalCity": "Ilaro",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 111,
        "destinationTerminalCity": "Imeko Afon",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 112,
        "destinationTerminalCity": "Ipokia",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 102,
        "destinationTerminalCity": "Obafemi-Owode",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 113,
        "destinationTerminalCity": "Odeda",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 114,
        "destinationTerminalCity": "Odogbolu",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 115,
        "destinationTerminalCity": "Ogun Waterside",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 116,
        "destinationTerminalCity": "Remo North",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 103,
        "destinationTerminalCity": "Sagamu",
        "destinationTerminalState":"Ogun"
    },
    {
        "id": 683,
        "destinationTerminalCity": "Akungba",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 678,
        "destinationTerminalCity": "Akure",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 684,
        "destinationTerminalCity": "Ese-Odo",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 685,
        "destinationTerminalCity": "Idanre",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 686,
        "destinationTerminalCity": "Ifedore",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 679,
        "destinationTerminalCity": "Iju/Itaogbolu",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 687,
        "destinationTerminalCity": "Ikare Akoko",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 688,
        "destinationTerminalCity": "Ilaje",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 689,
        "destinationTerminalCity": "Ile-Oluji-Okeigbo",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 690,
        "destinationTerminalCity": "Irele",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 691,
        "destinationTerminalCity": "Isua",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 692,
        "destinationTerminalCity": "Odigbo",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 693,
        "destinationTerminalCity": "Oka",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 694,
        "destinationTerminalCity": "Okeagbe",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 695,
        "destinationTerminalCity": "Okeigbo",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 680,
        "destinationTerminalCity": "Okitipupa",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 681,
        "destinationTerminalCity": "Ondo",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 696,
        "destinationTerminalCity": "Ose",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 682,
        "destinationTerminalCity": "Owo",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 683,
        "destinationTerminalCity": "Ore",
        "destinationTerminalState":"Ondo"
    },
    {
        "id": 702,
        "destinationTerminalCity": "Aiyedade",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 703,
        "destinationTerminalCity": "Aiyedire",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 704,
        "destinationTerminalCity": "Atakumosa East",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 705,
        "destinationTerminalCity": "Atakumosa West",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 706,
        "destinationTerminalCity": "Boluwaduro",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 697,
        "destinationTerminalCity": "Ede",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 707,
        "destinationTerminalCity": "Egbedore",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 698,
        "destinationTerminalCity": "Ife",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 708,
        "destinationTerminalCity": "Ifedayo",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 709,
        "destinationTerminalCity": "Ifelodun-Osun",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 710,
        "destinationTerminalCity": "Ila",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 699,
        "destinationTerminalCity": "Ilesa",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 711,
        "destinationTerminalCity": "Irepodun-Osun",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 712,
        "destinationTerminalCity": "Irewole",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 713,
        "destinationTerminalCity": "Isokan",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 714,
        "destinationTerminalCity": "Iwo",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 715,
        "destinationTerminalCity": "Obokun",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 716,
        "destinationTerminalCity": "Ola-Oluwa",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 700,
        "destinationTerminalCity": "Olorunda-Osun",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 717,
        "destinationTerminalCity": "Oriade",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 718,
        "destinationTerminalCity": "Orolu",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 701,
        "destinationTerminalCity": "Osogbo",
        "destinationTerminalState":"Osun"
    },
    {
        "id": 122,
        "destinationTerminalCity": "Afijio",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 117,
        "destinationTerminalCity": "Akinyele",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 123,
        "destinationTerminalCity": "Atiba",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 124,
        "destinationTerminalCity": "Atisbo",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 125,
        "destinationTerminalCity": "Ayete",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 118,
        "destinationTerminalCity": "Egbeda",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 126,
        "destinationTerminalCity": "Eruwa",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 119,
        "destinationTerminalCity": "Ibadan",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 120,
        "destinationTerminalCity": "Ido",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 127,
        "destinationTerminalCity": "Igbo Ora",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 128,
        "destinationTerminalCity": "Irepo",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 129,
        "destinationTerminalCity": "Iseyin",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 130,
        "destinationTerminalCity": "Itesiwaju",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 131,
        "destinationTerminalCity": "Iwajowa",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 132,
        "destinationTerminalCity": "Kajola",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 133,
        "destinationTerminalCity": "Lagelu",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 134,
        "destinationTerminalCity": "Ogbomosho North",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 135,
        "destinationTerminalCity": "Ogbomosho South",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 136,
        "destinationTerminalCity": "Ogo Oluwa",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 137,
        "destinationTerminalCity": "Olorunsogo",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 121,
        "destinationTerminalCity": "Oluyole",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 138,
        "destinationTerminalCity": "Ona-Ara"
    },
    {
        "id": 139,
        "destinationTerminalCity": "Orelope",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 140,
        "destinationTerminalCity": "Ori Ire",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 141,
        "destinationTerminalCity": "Oyo",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 142,
        "destinationTerminalCity": "Saki East",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 143,
        "destinationTerminalCity": "Saki West",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 144,
        "destinationTerminalCity": "Surulere-Oyo",
        "destinationTerminalState":"Oyo"
    },
    {
        "id": 720,
        "destinationTerminalCity": "Barkin Ladi",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 721,
        "destinationTerminalCity": "Bassa-Plateau",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 722,
        "destinationTerminalCity": "Bokkos",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 719,
        "destinationTerminalCity": "Jos",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 723,
        "destinationTerminalCity": "Kanam",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 724,
        "destinationTerminalCity": "Kanke",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 725,
        "destinationTerminalCity": "Langtang North",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 726,
        "destinationTerminalCity": "Langtang South",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 727,
        "destinationTerminalCity": "Mangu",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 728,
        "destinationTerminalCity": "Mikang",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 729,
        "destinationTerminalCity": "Pankshin",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 730,
        "destinationTerminalCity": "Quaan Pan",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 731,
        "destinationTerminalCity": "Riyom",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 732,
        "destinationTerminalCity": "Shendam",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 733,
        "destinationTerminalCity": "Wase",
        "destinationTerminalState":"Plateau"
    },
    {
        "id": 150,
        "destinationTerminalCity": "Abua/Odual",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 151,
        "destinationTerminalCity": "Ahoada",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 152,
        "destinationTerminalCity": "Akuku Toru",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 153,
        "destinationTerminalCity": "Andoni",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 154,
        "destinationTerminalCity": "Asari-Toru",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 155,
        "destinationTerminalCity": "Bonny",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 156,
        "destinationTerminalCity": "Degema",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 145,
        "destinationTerminalCity": "Eleme",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 157,
        "destinationTerminalCity": "Emohua",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 158,
        "destinationTerminalCity": "Etche",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 159,
        "destinationTerminalCity": "Gokana",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 146,
        "destinationTerminalCity": "Ikwerre",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 160,
        "destinationTerminalCity": "Khana",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 147,
        "destinationTerminalCity": "Obio-Akpor",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 161,
        "destinationTerminalCity": "Ogba/Egbema/Ndoni",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 162,
        "destinationTerminalCity": "Ogu/Bolo",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 163,
        "destinationTerminalCity": "Okrika",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 164,
        "destinationTerminalCity": "Omuma",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 148,
        "destinationTerminalCity": "Oyigbo",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 149,
        "destinationTerminalCity": "Port-Harcourt",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 165,
        "destinationTerminalCity": "Tai",
        "destinationTerminalState":"Rivers"
    },
    {
        "id": 737,
        "destinationTerminalCity": "Binji",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 738,
        "destinationTerminalCity": "Bodinga",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 739,
        "destinationTerminalCity": "Dange-Shuni",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 740,
        "destinationTerminalCity": "Gada",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 741,
        "destinationTerminalCity": "Goronyo",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 742,
        "destinationTerminalCity": "Gudu",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 743,
        "destinationTerminalCity": "Gwadabawa",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 734,
        "destinationTerminalCity": "Illela",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 744,
        "destinationTerminalCity": "Isa",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 745,
        "destinationTerminalCity": "Kebbe",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 746,
        "destinationTerminalCity": "Kware",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 747,
        "destinationTerminalCity": "Rabah",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 748,
        "destinationTerminalCity": "Sabon Birni",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 749,
        "destinationTerminalCity": "Shagari",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 750,
        "destinationTerminalCity": "Silame",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 735,
        "destinationTerminalCity": "Sokoto North",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 736,
        "destinationTerminalCity": "Sokoto South",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 751,
        "destinationTerminalCity": "Tambuwal",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 752,
        "destinationTerminalCity": "Tangaza",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 753,
        "destinationTerminalCity": "Tureta",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 754,
        "destinationTerminalCity": "Wamako",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 755,
        "destinationTerminalCity": "Wurno",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 756,
        "destinationTerminalCity": "Yabo",
        "destinationTerminalState":"Sokoto"
    },
    {
        "id": 760,
        "destinationTerminalCity": "Ardo-Kola",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 761,
        "destinationTerminalCity": "Bali",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 762,
        "destinationTerminalCity": "Donga",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 763,
        "destinationTerminalCity": "Gashaka",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 764,
        "destinationTerminalCity": "Gassol",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 765,
        "destinationTerminalCity": "Ibi",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 757,
        "destinationTerminalCity": "Jalingo",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 766,
        "destinationTerminalCity": "Karim-Lamido",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 767,
        "destinationTerminalCity": "Kurmi",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 768,
        "destinationTerminalCity": "Lau",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 769,
        "destinationTerminalCity": "Sardauna",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 758,
        "destinationTerminalCity": "Takum",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 770,
        "destinationTerminalCity": "Ussa",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 759,
        "destinationTerminalCity": "Wukari",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 771,
        "destinationTerminalCity": "Yorro",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 772,
        "destinationTerminalCity": "Zing",
        "destinationTerminalState":"Taraba"
    },
    {
        "id": 775,
        "destinationTerminalCity": "Bade",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 776,
        "destinationTerminalCity": "Bursari",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 773,
        "destinationTerminalCity": "Damaturu",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 777,
        "destinationTerminalCity": "Fika",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 778,
        "destinationTerminalCity": "Fune",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 779,
        "destinationTerminalCity": "Geidam",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 780,
        "destinationTerminalCity": "Gujba",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 781,
        "destinationTerminalCity": "Gulani",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 782,
        "destinationTerminalCity": "Jakusko",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 783,
        "destinationTerminalCity": "Karasuwa",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 784,
        "destinationTerminalCity": "Machina",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 785,
        "destinationTerminalCity": "Nangere",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 786,
        "destinationTerminalCity": "Nguru",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 774,
        "destinationTerminalCity": "Potiskum",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 787,
        "destinationTerminalCity": "Tarmua",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 788,
        "destinationTerminalCity": "Yunusari",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 789,
        "destinationTerminalCity": "Yusufari",
        "destinationTerminalState":"Yobe"
    },
    {
        "id": 791,
        "destinationTerminalCity": "Anka",
        "destinationTerminalState":"Zamfara"
    },
    {
        "id": 792,
        "destinationTerminalCity": "Bakura",
        "destinationTerminalState":"Zamfara"
    },
    {
        "id": 793,
        "destinationTerminalCity": "Birnin Magaji",
        "destinationTerminalState":"Zamfara"
    },
    {
        "id": 794,
        "destinationTerminalCity": "Bukkuyum",
        "destinationTerminalState":"Zamfara"
    },
    {
        "id": 795,
        "destinationTerminalCity": "Bungudu",
        "destinationTerminalState":"Zamfara"
    },
    {
        "id": 796,
        "destinationTerminalCity": "Gummi",
        "destinationTerminalState":"Zamfara"
    },
    {
        "id": 790,
        "destinationTerminalCity": "Gusau",
        "destinationTerminalState":"Zamfara"
    },
    {
        "id": 797,
        "destinationTerminalCity": "Kaura Namoda",
        "destinationTerminalState":"Zamfara"
    },
    {
        "id": 798,
        "destinationTerminalCity": "Maradun",
        "destinationTerminalState":"Zamfara"
    },
    {
        "id": 799,
        "destinationTerminalCity": "Maru",
        "destinationTerminalState":"Zamfara"
    },
    {
        "id": 800,
        "destinationTerminalCity": "Shinkafi",
        "destinationTerminalState":"Zamfara"
    },
    {
        "id": 801,
        "destinationTerminalCity": "Talata Mafara",
        "destinationTerminalState":"Zamfara"
    },
    {
        "id": 802,
        "destinationTerminalCity": "Tsafe",
        "destinationTerminalState":"Zamfara"
    },
    {
        "id": 803,
        "destinationTerminalCity": "Zurmi",
        "destinationTerminalState":"Zamfara"
    }
]