<template>
  <v-col sm="12" class="main-body">
    <button
      class="tw-flex tw-items-center overview-btn tw-mt-20 lg:tw-mt-6"
      @click="backToTrips">
      <icons name="arrow-back" :color="companyColor" />
      <span class="tw-ml-4" :style="{ color: companyColor }">All Trips</span>
    </button>

    <v-col sm="12" class="tw-bg-white tw-mt-7 tw-rounded-xl
     tw-py-8 card tw-items-center tw-flex tw-flex-col tw-w-full tw-px-8 ">
      <div class="tw-flex tw-w-full tw-justify-between tw-items-start tw-px-4">
        <div class="img-div tw-flex tw-items-center">
          <div class="img-text-div ">
            <p class="header text-capitalize">{{toSentenceCase(tripData.routeDepartureTerminalName)}} {{toSentenceCase(tripData.routeDepartureCity)}}
              - {{toSentenceCase(tripData.routeDestinationTerminalName)}} {{toSentenceCase( tripData.routeDestinationCity)}}</p>
            <p class="header-id tw-mt-5">{{getDate(tripData.takeOffDate)}}</p>
          </div>
        </div>

          <div class="tw-flex tw-items-center" v-if="tripData.status === 'DRAFT' && Object.keys(!staffData).length">
            <button class="tw-pl-5" @click="handleShowDelete">
              <icons name="trash" :color="companyColor" />
            </button>
          </div>
        <div class="tw-flex tw-items-center" v-if="tripData.status === 'DRAFT' && staffData.terminalAssignedId === tripData.terminalId">
          <button class="tw-pl-5" @click="handleShowDelete">
            <icons name="trash" :color="companyColor" />
          </button>
        </div>

      </div>

      <v-simple-table class="tw-mt-8  tw-w-full">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Fleet
              </th>
              <th class="text-left">
                Route
              </th>
              <th class="text-left">
                Take of Date
              </th>
              <th class="text-left">
                Time
              </th>
              <th class="text-left">
                Status
              </th>
              <th class="text-left">
                Price
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{tripData && tripData.fleetForThisTrip ? tripData.fleetForThisTrip.vehicleRegNumber : 'UNASSIGNED'}}</td>
              <td class="text-capitalize">{{toSentenceCase(tripData.routeDepartureTerminalName)}} {{toSentenceCase(tripData.routeDepartureCity)}} {{toSentenceCase(tripData.routeDepartureState)}}
                - {{toSentenceCase(tripData.routeDestinationTerminalName)}} {{toSentenceCase(tripData.routeDestinationCity)}} {{toSentenceCase(tripData.routeDestinationState)}}</td>
              <td>{{getDate(tripData.takeOffDate)}}</td>
              <td>{{getTime(tripData.takeOffDate,tripData.takeOffTime)}}</td>
              <td > <span class="status-div text-capitalize" :style="{backgroundColor : getColor(tripData.status)}">{{tripData.status}}</span></td>
              <td v-if="tripData.tripRegion === 'local' || !tripData.tripRegion">&#8358; {{tripData.tripAmount}}</td>
              <td v-else>
                  <div class="tw-flex tw-flex-col ">
                      <div class="tw-flex tw-flex-row tw-py-2" v-for="(tripCategory, index) in tripData.tripCategories" :key="index">
                          {{tripCategory.type}}  &#8358; {{tripCategory.tripAmount}}
                      </div>
                  </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-row class="tw-mt-3 tw-flex-col lg:tw-flex-row">
      <v-col sm="12" md="6" class="tw-px-2 tw-py-2" v-if="showSecond">
        <div class=" tw-w-full tw-bg-white tw-px-4 tw-py-3 tw-rounded-xl next-card">
          <div class="tw-w-full tw-flex tw-justify-end">
            <v-btn text :color="companyColor" class="tw-text-right"
                   style="text-transform: none" @click="viewTrip(secondTripData.id)">View</v-btn>
          </div>
          <div class="tw-flex tw-w-full tw-justify-start tw-items-center tw-mt-2">
            <div class="card-div tw-flex tw-items-center">
              <div class="img-text-div tw-ml-5">
                <p class="header text-capitalize">{{secondTripData.routeDepartureCity}} {{secondTripData.routeDepartureState}}
                  - {{secondTripData.routeDestinationCity}} {{secondTripData.routeDestinationState}}</p>
                <p class="header-id">{{getDate(secondTripData.takeOffDate)}}</p>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col sm="12" md="6" class="tw-py-2 tw-px-2" v-if="showThird">
        <div class=" tw-w-full tw-bg-white tw-px-4 tw-py-3 tw-rounded-xl next-card">
          <div class="tw-w-full tw-flex tw-justify-end">
            <v-btn text :color="companyColor" class="tw-text-right"
                   style="text-transform: none" @click="viewTrip(thirdTripData.id)">View</v-btn>
          </div>
          <div class="tw-flex tw-w-full tw-justify-start tw-items-center tw-mt-2">
            <div class="card-div tw-flex tw-items-center">
              <div class="img-text-div tw-ml-5">
                <p class="header text-capitalize">{{thirdTripData.routeDepartureCity}} {{thirdTripData.routeDepartureState}}
                  - {{thirdTripData.routeDestinationCity}} {{thirdTripData.routeDestinationState}}</p>
                <p class="header-id">{{getDate(thirdTripData.takeOffDate)}}</p>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <delete-modal :dialog="showDelete"
                  description="Are you sure you want to delete this trip"
                  @deleteAction="deleteTrip" @close="handleShowDelete" :done-loading="deleteLoading" />

  </v-col>
</template>

<script>
import Icons from "../../reuseables/Icons";
import dayjs from "dayjs";
import DeleteModal from "../../reuseables/DeleteModal";
import {deleteTrip} from "../../../services/api/APIService";
export default {
  name: "SingleTrip",
  components: {DeleteModal, Icons },
  data() {
    return {
      companyData: {},
      companyColor: "#004AAD",
      tripData:{},
      trips:[],
      currentTripId : null,
      secondTripData:{},
      thirdTripData:{},
      showSecond: false,
      showThird: false,
      showDelete: false,
      deleteLoading: false,
      userData:{},
      staffData:{}
    };
  },
  methods: {
    getColor(status){
      if (status.toUpperCase() === "PENDING"){
        return "#A828B4"
      }
      else if (status.toUpperCase() === "ONGOING"){
        return "#4CD171"
      }
      else if (status.toUpperCase() === "COMPLETED"){
        return "#1C60BC"
      }
      else if (status.toUpperCase() === "CANCELED"){
        return "#F54848"
      }
      else {
        return "#2E2E2E"
      }
    },
    backToTrips() {
      if (Object.keys(this.staffData).length){
        this.$store.dispatch(
            "terminalManagerTripComponent/setCurrentComponent",
            "allTrips"
        );
        sessionStorage.setItem("terminalManagerTripComponent", "allTrips");
      }
      else {
        this.$store.dispatch("tripComponent/setCurrentComponent", "allTrips");
        sessionStorage.setItem("tripComponent", "allTrips");
      }
    },
    selectEditTab() {
      this.$store.dispatch("tripComponent/setCurrentComponent", "editTrip");
      sessionStorage.setItem("tripComponent", "editTrip");
    },
    getCurrentTrip(){
      let nextTripId = null
      let currentId = null
      if (this.currentTripId){

       this.tripData = this.trips.find(trip => trip.id === Number.parseInt(this.currentTripId))

        if (this.tripData.id){
          currentId = this.trips.findIndex(trip => trip === this.tripData)
          if (currentId+1 < this.trips.length) {
            this.secondTripData = this.trips[currentId + 1]

            this.showSecond = true
          }else {
            this.showSecond = false
            this.showThird = false
          }
        }
        if (this.secondTripData.id){
          nextTripId = this.trips.findIndex(trip => trip === this.secondTripData)
          if (nextTripId+1 < this.trips.length) {
            this.thirdTripData = this.trips[nextTripId + 1]
            this.showThird = true
          }else {this.showThird = false}
        }
        else {
          this.showSecond = false
        }
      }
      else {this.showSecond = false}

    },
    getTime(date, time) {
      return dayjs(date+time,'ll').format('LT')
    },
    getDate(date){
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      if (dayjs(date).toDate().toDateString()=== today.toDateString()) {
        return "Today";
      } else if (dayjs(date).toDate().toDateString() === yesterday.toDateString()) {
        return "Yesterday";
      } else {
        return dayjs(date).toDate().toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
      }
    },
      toSentenceCase(text){
          text = text.toLowerCase()
          return text.replace(text.charAt(0),text.charAt(0).toUpperCase())
      },
    getDateFromDatetime(datetimeStr) {
      const date = dayjs(datetimeStr).toDate();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const convertDate = `${year}-${month}-${day}`;
      console.log(convertDate,datetimeStr)
      return this.getDate(convertDate);
    },
    viewTrip(tripId){
      this.currentTripId = tripId
      this.getCurrentTrip()
    },
    deleteTrip(){
      this.deleteLoading = true
      let data = {}
      data.transportCompanyId = this.companyData.id
      data.tripId = this.currentTripId
      deleteTrip(data).then(() =>{
        this.deleteLoading = false;
        this.handleShowDelete()
        this.backToTrips()
      }).catch((err) => {
        this.deleteLoading = false;
        console.log(err.response);
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      });
    },
    handleShowDelete(){
      this.showDelete = !this.showDelete
    },
  },
  async created() {
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let userData = JSON.parse(sessionStorage.getItem("userData"))
    let staffData = JSON.parse(sessionStorage.getItem("staffData"))
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    if (userData){
      this.userData = userData
    }
    if (staffData){
      this.staffData = staffData
    }
    let trips = JSON.parse(sessionStorage.getItem("trips"))
    if (trips.length){
      this.trips = trips
    }
    let currentTripId = sessionStorage.getItem("currentTripId")
    if (currentTripId){
      this.currentTripId = currentTripId
    }
    this.getCurrentTrip()
  },
};
</script>

<style lang="scss" scoped>
.view-link {
  font-family: "Inter",sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}

.overview-btn {
  border: none !important;
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
.card{
  min-height: 397px;
  height: max-content;
}

.card-div {
  img {
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }
}
.header {
  font-family: "Inter",sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
  text-transform: capitalize !important;
}

.header-id {
  font-family: "Inter",sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
}
.next-card{
  height: 202px;
}
.img-div {
  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .header {
    font-family: "Inter",sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .header-id {
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: 0px solid rgba(0, 0, 0, 0.12) !important;
}

.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter",sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  text-transform: capitalize;

}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;

}

.status-div{
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 25px;
  border-radius: 20px;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 8px;
  color: #FDFFFC;
  text-transform: capitalize;
}
</style>
